/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSearch } from 'wouter';

import useDocument from '../../hooks/useDocument';
import * as AppPropTypes from '../../lib/PropTypes';

import {
    formats as defaultFormats,
    variations as defaultVariations,
    defaultProps as defaultShareProps,
} from '../../data/share';
import DocumentFeaturedCard from '../cards/DocumentFeaturedCard';

import styles from '../../styles/pages/document-share-image-page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    variation: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    formats: PropTypes.arrayOf(AppPropTypes.shareFormat),
    variations: PropTypes.arrayOf(AppPropTypes.shareVariation),
    className: PropTypes.string,
    contentClassName: PropTypes.string,
};

const defaultProps = {
    formats: defaultFormats,
    variations: defaultVariations,
    disabled: false,
    className: null,
    contentClassName: null,
};
function DocumentShareImagePage({
    slug,
    variation: variationId,
    formats,
    variations,
    disabled,
    className,
    contentClassName,
}) {
    const {
        document: currentDocument = null,
        // notFound = false,
        // isFetching = false,
    } = useDocument(slug, {
        snippetOnly: true,
        keepPreviousData: false,
        enabled: true,
    });

    const search = useSearch();

    const variation = variations.find(({ id: itId }) => itId === variationId) || variations[0];
    const { format: formatId } = variation;
    const { width, height } = formats.find(({ id: itId }) => itId === formatId) || formats[0];

    const {
        x = 0.5,
        y = 0.5,
        scale = null,
        labelBackground = null,
        textColor = null,
        urbaniaLogoSize = null,
        textShadow = null,
        format,
        id,
        label,
        ...props
    } = useMemo(
        () => ({
            ...defaultShareProps,
            ...variation,
            ...queryString.parse(search),
        }),
        [search, variation],
    );

    const finalScale = isString(scale) ? parseFloat(scale) : scale;
    const positionX = isString(x) ? parseFloat(x) : 0.5;
    const positionY = isString(y) ? parseFloat(y) : 0.5;

    const ref = useRef(null);
    useEffect(() => {
        ref.current.style.setProperty('--card-label-background', labelBackground);
        ref.current.style.setProperty('--card-color', textColor);
        ref.current.style.setProperty(
            '--card-urbania-logo-width',
            urbaniaLogoSize !== null ? `${urbaniaLogoSize * 100}%` : null,
        );
        ref.current.style.setProperty(
            '--card-featured-text-shadow',
            textShadow !== null
                ? `0 0 ${0.1 + textShadow * 0.2}em rgba(0,0,0,${0.5 + textShadow * 0.5})`
                : null,
        );
    }, [labelBackground, textColor, urbaniaLogoSize, textShadow]);

    return (
        <div className={classNames([styles.container, className, contentClassName])}>
            <div
                className={styles.frame}
                style={{
                    width,
                    height,
                }}
                ref={ref}
            >
                <div
                    className={styles.scale}
                    style={
                        finalScale !== null
                            ? {
                                  width: Math.round(width / finalScale),
                                  height: Math.round(height / finalScale),
                                  transform: `scale(${finalScale})`,
                                  transformOrigin: 'top left',
                              }
                            : null
                    }
                >
                    {currentDocument !== null ? (
                        <DocumentFeaturedCard
                            theme="over-bottom-left"
                            imageSize="large"
                            {...currentDocument}
                            {...Object.keys(props).reduce((map, key) => {
                                let val = props[key];
                                if (val === 'false') {
                                    val = false;
                                } else if (val === 'true') {
                                    val = true;
                                }
                                return {
                                    ...map,
                                    [key]: props[key],
                                };
                            }, {})}
                            className={styles.card}
                            pictureStyle={{
                                objectPosition: `${positionX * 100}% ${positionY * 100}%`,
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}

DocumentShareImagePage.propTypes = propTypes;
DocumentShareImagePage.defaultProps = defaultProps;

export default DocumentShareImagePage;
