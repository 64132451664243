/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDrag } from '@use-gesture/react';
import classNames from 'classnames';
import copy from 'clipboard-copy';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import slugify from 'slugify';
import { useParams } from 'wouter';

import useDocument from '../../hooks/useDocument';
import useElementSize from '../../hooks/useElementSize';
import * as AppPropTypes from '../../lib/PropTypes';

import { useSite } from '../../contexts/SiteContext';
import {
    formats as defaultFormats,
    variations as defaultVariations,
    defaultProps as defaultShareProps,
    utmSources,
    utmMediums,
} from '../../data/share';
import BasicButton from '../buttons/BasicButton';
import CloseButton from '../buttons/CloseButton';
import RoundedButton from '../buttons/RoundedButton';
import ToggleButton from '../buttons/ToggleButton';
import UrbaniaButton from '../buttons/UrbaniaButton';
import DocumentFeaturedCard from '../cards/DocumentFeaturedCard';
import ColorField from '../fields/ColorField';
import RangeField from '../fields/RangeField';
import SelectField from '../fields/SelectField';
import TextField from '../fields/TextField';
import TextareaField from '../fields/TextareaField';
import FormControl from '../forms/FormControl';

import styles from '../../styles/pages/document-share-page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    disabled: PropTypes.bool,
    formats: PropTypes.arrayOf(AppPropTypes.shareFormat),
    variations: PropTypes.arrayOf(AppPropTypes.shareVariation),
    className: PropTypes.string,
    contentClassName: PropTypes.string,
};

const defaultProps = {
    formats: defaultFormats,
    variations: defaultVariations,
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    disabled: false,
    className: null,
    contentClassName: null,
};
function DocumentSharePage({
    slug,
    formats,
    variations,
    entered,
    entering,
    leaving,
    current,
    disabled,
    className,
    contentClassName,
}) {
    const { handle: siteHandle } = useSite();
    const { type } = useParams();
    const url = useUrlGenerator();
    const { document: currentDocument = null } = useDocument(slug, {
        snippetOnly: true,
        keepPreviousData: false,
        enabled: !disabled,
    });
    const {
        type: documentType,
        title,
        image = null,
        micromagNumber = null,
    } = currentDocument || {};
    const { url: imageUrl = null } = image || {};
    const documentUrl = `https://${siteHandle === 'france' ? 'urbania.fr' : 'urbania.ca'}${url('document', { type, slug })}`;
    const shortUrl =
        documentType === 'micromag' && micromagNumber != null
            ? `https://${siteHandle === 'france' ? 'micromag.fr' : 'micromag.ca'}/${micromagNumber}`
            : null;

    const { ref: resizeRef, width: frameWidth, height: frameHeight } = useElementSize();
    const refFrames = useRef({});
    const [framesScales, setFramesScales] = useState({});
    const [globalProps, setGlobalProps] = useState({});
    const [variationsProps, setVariationsProps] = useState({});
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const [editVariation, setEditVariation] = useState(null);

    useEffect(() => {
        const newFramesScales = Object.keys(refFrames.current).reduce((map, key) => {
            const { format: formatId } = variations.find(({ id }) => id === key);
            const format = formats.find(({ id: fId }) => fId === formatId);
            return {
                ...map,
                [key]: refFrames.current[key].clientWidth / format.width,
            };
        }, {});
        setFramesScales(newFramesScales);
    }, [frameWidth, frameHeight]);

    const bind = useDrag(
        ({ offset: [x, y] }) => {
            setVariationsProps({
                ...variationsProps,
                [editVariation]: {
                    ...(variationsProps[editVariation] || {}),
                    x: Math.min(Math.max(0.5 + -x / 400, 0), 1),
                    y: Math.min(Math.max(0.5 + -y / 400, 0), 1),
                },
            });
        },
        {
            // axis: 'xy',
        },
    );

    const onClickEditVariation = useCallback((e, key) => {
        setSidebarOpened(true);
        setEditVariation(key);
    }, []);

    const onClickEdit = useCallback(() => {
        setSidebarOpened(true);
        setEditVariation(null);
    }, []);

    const onClickClose = useCallback(() => {
        setSidebarOpened(false);
        setEditVariation(null);
    }, []);

    const onUpdateField = useCallback(
        (field, newValue) => {
            if (editVariation !== null) {
                const { [field]: currentValue, ...otherVariationProps } =
                    (variationsProps || {})[editVariation] || {};
                setVariationsProps(
                    newValue !== null || typeof currentDocument[field] === 'undefined'
                        ? {
                              ...variationsProps,
                              [editVariation]: {
                                  ...otherVariationProps,
                                  [field]: newValue,
                              },
                          }
                        : {
                              ...variationsProps,
                              [editVariation]: otherVariationProps,
                          },
                );
            } else {
                const { [field]: currentValue, ...otherGlobalProps } = globalProps;
                setGlobalProps(
                    newValue !== null || typeof currentDocument[field] === 'undefined'
                        ? {
                              ...otherGlobalProps,
                              [field]: newValue,
                          }
                        : otherGlobalProps,
                );
            }
        },
        [editVariation, variationsProps, globalProps, currentDocument],
    );

    const {
        title: editTitle = null,
        surtitle: editSurtitle = null,
        description: editDescription = null,
        scale: editScale = null,
        withUrbaniaLogo: editWithUrbaniaLogo = null,
        withoutSurtitle: editWithoutSurtitle = null,
        withoutAuthorImage: editWithoutAuthorImage = null,
        withoutAuthor: editWithoutAuthor = null,
        authorPrefix: editAuthorPrefix = null,
        labelBackground: editLabelBackground = null,
        textColor: editTextColor = null,
        textShadow: editTextShadow = null,
        urbaniaLogoSize: editUrbaniaLogoSize = null,
    } = {
        ...defaultShareProps,
        ...(editVariation !== null ? variations.find(({ id }) => id === editVariation) : null),
        ...(editVariation !== null
            ? (variationsProps || {})[editVariation] || {}
            : globalProps || {}),
    };

    useEffect(() => {
        Object.keys(refFrames.current).forEach((key) => {
            const variation = variations.find(({ id }) => id === key);
            const {
                labelBackground = null,
                textColor = null,
                urbaniaLogoSize = null,
                textShadow = null,
            } = {
                ...defaultShareProps,
                ...variation,
                ...(variationsProps[key] || {}),
            };
            refFrames.current[key].style.setProperty('--card-label-background', labelBackground);
            refFrames.current[key].style.setProperty('--card-color', textColor);
            refFrames.current[key].style.setProperty(
                '--card-urbania-logo-width',
                urbaniaLogoSize !== null ? `${urbaniaLogoSize * 100}%` : null,
            );
            refFrames.current[key].style.setProperty(
                '--card-featured-text-shadow',
                textShadow !== null
                    ? `0 0 ${0.1 + textShadow * 0.2}em rgba(0,0,0,${0.5 + textShadow * 0.5})`
                    : null,
            );
        });
    }, [variationsProps]);

    const [fieldCopied, setFieldCopied] = useState(null);
    const onFieldCopy = useCallback((field, copyValue) => {
        copy(copyValue);
        setFieldCopied(field);
    }, []);

    useEffect(() => {
        let timeout = null;
        if (fieldCopied !== null) {
            timeout = setTimeout(() => {
                setFieldCopied(null);
            }, 2000);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [fieldCopied]);

    const metadata = useMemo(
        () =>
            [
                {
                    id: 'title',
                    label: 'Titre',
                    value: title,
                },
                {
                    id: 'url',
                    label: 'URL',
                    value: documentUrl,
                },
                {
                    id: 'url',
                    label: 'URL courte',
                    value: shortUrl,
                },
                {
                    id: 'image',
                    label: 'Image',
                    value: imageUrl,
                },
            ].filter(({ value }) => value !== null),
        [title, documentUrl, imageUrl],
    );

    const [utmQuery, setUtmQuery] = useState({
        domain: siteHandle === 'france' ? 'urbania.fr' : 'urbania.ca',
        utm_source: utmSources[0].value,
        utm_medium: utmMediums[0].value,
        utm_campaign: null,
    });

    const onUtmChange = useCallback(
        (field, newValue) => {
            setUtmQuery({
                ...utmQuery,
                [field]: newValue,
            });
        },
        [utmQuery, setUtmQuery],
    );

    const shareUrl = `https://${utmQuery.domain}${url('document', { type, slug })}?${queryString.stringify(
        {
            ...utmQuery,
            domain: null,
            utm_campaign: slugify(utmQuery.utm_campaign || '', {
                replacement: '_',
                lower: true,
            }),
        },
        {
            skipNull: true,
        },
    )}`;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.entered]: entered,
                    [styles.entering]: entering,
                    [styles.leaving]: leaving,
                    [styles.sidebarOpened]: sidebarOpened,
                },
                className,
                contentClassName,
            ])}
        >
            <UrbaniaButton className={styles.urbania} />

            <div className={styles.inner}>
                <header className={styles.header}>
                    <h1 className={styles.title}>Partage</h1>
                </header>

                <div className={styles.section}>
                    <div className={styles.top}>
                        <h2 className={styles.title}>Métadonnées</h2>
                    </div>
                    {metadata.map(({ id, label, value }) => (
                        <div className={styles.metadata} key={`metadata-${id}`}>
                            <label className={styles.label} htmlFor={`metadata-${id}`}>
                                {label} :
                            </label>
                            <div className={styles.field}>
                                <TextField
                                    id={`metadata-${id}`}
                                    type="text"
                                    value={value}
                                    readOnly
                                    onFocus={(e) => e.currentTarget.select()}
                                    className={styles.input}
                                />
                                <BasicButton
                                    className={styles.copyButton}
                                    onClick={() => onFieldCopy(id, value)}
                                >
                                    <FontAwesomeIcon
                                        icon={fieldCopied === id ? faCircleCheck : faCopy}
                                        className={styles.copyIcon}
                                    />
                                </BasicButton>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={styles.section}>
                    <div className={styles.top}>
                        <h2 className={styles.title}>Générateur de lien</h2>
                    </div>
                    <div className={styles.utmBuilder}>
                        <div className={styles.form}>
                            <FormControl
                                label="Domaine"
                                className={classNames([styles.formControl, styles.row])}
                            >
                                <SelectField
                                    options={
                                        siteHandle === 'france'
                                            ? ['urbania.fr', 'micromag.fr']
                                            : [
                                                  'urbania.ca',
                                                  'urbania.tv',
                                                  'quatre95.ca',
                                                  'micromag.ca',
                                                  'mollo.media',
                                                  'dehors.media',
                                              ]
                                    }
                                    value={utmQuery.domain}
                                    onChange={(newValue) => onUtmChange('domain', newValue)}
                                    className={styles.field}
                                />
                            </FormControl>
                            <FormControl
                                label="Source"
                                className={classNames([styles.formControl, styles.row])}
                            >
                                <SelectField
                                    options={utmSources}
                                    value={utmQuery.utm_source}
                                    onChange={(newValue) => onUtmChange('utm_source', newValue)}
                                    className={styles.field}
                                />
                            </FormControl>
                            <FormControl
                                label="Placement"
                                className={classNames([styles.formControl, styles.row])}
                            >
                                <SelectField
                                    options={utmMediums}
                                    value={utmQuery.utm_medium}
                                    onChange={(newValue) => onUtmChange('utm_medium', newValue)}
                                    className={styles.field}
                                />
                            </FormControl>
                            <FormControl
                                label="Nom de la personne ou de campagne"
                                className={classNames([styles.formControl, styles.row])}
                            >
                                <TextField
                                    value={utmQuery.utm_campaign}
                                    onChange={(newValue) => onUtmChange('utm_campaign', newValue)}
                                    className={styles.field}
                                />
                            </FormControl>
                        </div>
                        <div className={styles.preview}>
                            <div className={styles.field}>
                                <TextareaField
                                    value={shareUrl}
                                    onFocus={(e) => e.currentTarget.select()}
                                    className={styles.textarea}
                                />
                                <BasicButton
                                    className={styles.copyButton}
                                    onClick={() => onFieldCopy('shareUrl', shareUrl)}
                                >
                                    <FontAwesomeIcon
                                        icon={fieldCopied === 'shareUrl' ? faCircleCheck : faCopy}
                                        className={styles.copyIcon}
                                    />
                                </BasicButton>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.top}>
                        <h2 className={styles.title}>Images</h2>
                        <div className={styles.actions}>
                            <RoundedButton onClick={onClickEdit} className={styles.button} compact>
                                Modifier
                            </RoundedButton>
                        </div>
                    </div>

                    {formats.map((format) => {
                        const { id: formatId, label: formatLabel, width, height } = format;
                        const formatVariations = variations.filter(
                            ({ format: fId }) => fId === formatId,
                        );
                        return (
                            <div className={styles.format} key={`format-${formatId}`}>
                                <h4 className={styles.label}>
                                    {formatLabel} ({width}x{height})
                                </h4>
                                <div className={styles.variations}>
                                    {formatVariations.map(
                                        (
                                            {
                                                id: variationId,
                                                label: variationLabel,
                                                scale: variationScale = null,
                                                ...props
                                            },
                                            index,
                                        ) => {
                                            const {
                                                x = null,
                                                y = null,
                                                scale = null,
                                            } = variationsProps[variationId] || {};
                                            const downloadQuery = {
                                                ...globalProps,
                                                ...variationsProps[variationId],
                                            };
                                            const finalScale = scale || variationScale;
                                            const isEditing = editVariation === variationId;

                                            return (
                                                <div
                                                    className={classNames([
                                                        styles.variation,
                                                        {
                                                            [styles.isEditing]: isEditing,
                                                        },
                                                    ])}
                                                    key={`variation-${variationId}`}
                                                >
                                                    <div className={styles.center}>
                                                        <div className={styles.top}>
                                                            <h5 className={styles.label}>
                                                                {variationLabel}
                                                            </h5>

                                                            <div className={styles.actions}>
                                                                <RoundedButton
                                                                    onClick={(e) =>
                                                                        onClickEditVariation(
                                                                            e,
                                                                            variationId,
                                                                        )
                                                                    }
                                                                    className={classNames([
                                                                        styles.button,
                                                                        styles.editButton,
                                                                    ])}
                                                                    compact
                                                                >
                                                                    Modifier
                                                                </RoundedButton>
                                                                <RoundedButton
                                                                    href={`${url(
                                                                        'document.share_image',
                                                                        {
                                                                            type,
                                                                            slug,
                                                                            variation: variationId,
                                                                        },
                                                                    )}?${queryString.stringify(downloadQuery)}`}
                                                                    download={`${title} - ${formatLabel} - ${variationLabel}.jpg`}
                                                                    external
                                                                    target="_self"
                                                                    className={styles.button}
                                                                    compact
                                                                >
                                                                    Télécharger
                                                                </RoundedButton>
                                                            </div>
                                                        </div>
                                                        <div className={styles.frame}>
                                                            <div
                                                                className={styles.ratio}
                                                                style={{
                                                                    paddingBottom: `${(height / width) * 100}%`,
                                                                }}
                                                                ref={(ref) => {
                                                                    if (index === 0) {
                                                                        resizeRef.current = ref;
                                                                    }
                                                                    refFrames.current[variationId] =
                                                                        ref;
                                                                }}
                                                                {...(isEditing ? bind() : null)}
                                                            >
                                                                <div
                                                                    className={styles.scale}
                                                                    style={{
                                                                        width,
                                                                        height,
                                                                        transform: `scale(${framesScales[variationId] || 1})`,
                                                                        transformOrigin: 'top left',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={styles.scale}
                                                                        style={
                                                                            finalScale !== null
                                                                                ? {
                                                                                      width: Math.round(
                                                                                          width /
                                                                                              finalScale,
                                                                                      ),
                                                                                      height: Math.round(
                                                                                          height /
                                                                                              finalScale,
                                                                                      ),
                                                                                      transform: `scale(${finalScale})`,
                                                                                      transformOrigin:
                                                                                          'top left',
                                                                                  }
                                                                                : null
                                                                        }
                                                                    >
                                                                        {currentDocument !==
                                                                        null ? (
                                                                            <DocumentFeaturedCard
                                                                                theme="over-bottom-left"
                                                                                imageSize="large"
                                                                                {...defaultShareProps}
                                                                                {...props}
                                                                                {...currentDocument}
                                                                                {...globalProps}
                                                                                {...variationsProps[
                                                                                    variationId
                                                                                ]}
                                                                                className={
                                                                                    styles.card
                                                                                }
                                                                                pictureStyle={{
                                                                                    objectPosition:
                                                                                        x !==
                                                                                            null &&
                                                                                        y !== null
                                                                                            ? `${x * 100}% ${y * 100}%`
                                                                                            : null,
                                                                                }}
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className={styles.sidebar}>
                <CloseButton onClick={onClickClose} className={styles.closeButton} />
                <FormControl label="Titre" className={styles.formControl}>
                    <TextField
                        className={styles.field}
                        value={editTitle}
                        onChange={(newValue) => onUpdateField('title', newValue)}
                    />
                </FormControl>
                <FormControl label="Surtitre" className={styles.formControl}>
                    <TextField
                        className={styles.field}
                        value={editSurtitle}
                        onChange={(newValue) => onUpdateField('surtitle', newValue)}
                    />
                </FormControl>
                <FormControl label="Description" className={styles.formControl}>
                    <TextField
                        className={styles.field}
                        value={editDescription}
                        onChange={(newValue) => onUpdateField('description', newValue)}
                    />
                </FormControl>
                <FormControl label="Crédit" className={styles.formControl}>
                    <TextField
                        className={styles.field}
                        value={editAuthorPrefix}
                        onChange={(newValue) => onUpdateField('authorPrefix', newValue)}
                    />
                </FormControl>

                <FormControl
                    label="Logo URBANIA"
                    className={classNames([styles.formControl, styles.row])}
                >
                    <ToggleButton
                        value={editWithUrbaniaLogo !== null ? editWithUrbaniaLogo : true}
                        onChange={(newValue) => onUpdateField('withUrbaniaLogo', newValue)}
                        className={styles.toggleButton}
                    />
                </FormControl>

                <FormControl
                    label="Surtitre"
                    className={classNames([styles.formControl, styles.row])}
                >
                    <ToggleButton
                        value={editWithoutSurtitle !== null ? !editWithoutSurtitle : true}
                        onChange={(newValue) => onUpdateField('withoutSurtitle', !newValue)}
                        className={styles.toggleButton}
                    />
                </FormControl>

                <FormControl
                    label="Auteur"
                    className={classNames([styles.formControl, styles.row])}
                >
                    <ToggleButton
                        value={editWithoutAuthor !== null ? !editWithoutAuthor : true}
                        onChange={(newValue) => onUpdateField('withoutAuthor', !newValue)}
                        className={styles.toggleButton}
                    />
                </FormControl>

                <FormControl
                    label="Image de l’auteur"
                    className={classNames([styles.formControl, styles.row])}
                >
                    <ToggleButton
                        value={editWithoutAuthorImage !== null ? !editWithoutAuthorImage : false}
                        onChange={(newValue) => onUpdateField('withoutAuthorImage', !newValue)}
                        className={styles.toggleButton}
                    />
                </FormControl>

                {editVariation !== null ? (
                    <FormControl
                        label="Taille"
                        className={classNames([styles.formControl, styles.row])}
                    >
                        <RangeField
                            min={0.5}
                            max={5}
                            step={0.1}
                            value={editScale}
                            onChange={(newValue) => onUpdateField('scale', newValue)}
                        />
                    </FormControl>
                ) : null}

                {editVariation !== null ? (
                    <FormControl
                        label="Taille du logo URBANIA"
                        className={classNames([styles.formControl, styles.row])}
                    >
                        <RangeField
                            min={0.1}
                            max={0.9}
                            step={0.05}
                            value={editUrbaniaLogoSize}
                            onChange={(newValue) => onUpdateField('urbaniaLogoSize', newValue)}
                        />
                    </FormControl>
                ) : null}

                {editVariation !== null ? (
                    <FormControl
                        label="Ombre du texte"
                        className={classNames([styles.formControl, styles.row])}
                    >
                        <RangeField
                            min={0.0}
                            max={1.0}
                            step={0.05}
                            value={editTextShadow}
                            onChange={(newValue) => onUpdateField('textShadow', newValue)}
                        />
                    </FormControl>
                ) : null}

                {editVariation !== null ? (
                    <FormControl
                        label="Couleur du texte"
                        className={classNames([styles.formControl, styles.row])}
                    >
                        <ColorField
                            value={editTextColor}
                            onChange={(newValue) => onUpdateField('textColor', newValue)}
                        />
                    </FormControl>
                ) : null}

                {editVariation !== null ? (
                    <FormControl
                        label="Couleur d’arrière-plan"
                        className={classNames([styles.formControl, styles.row])}
                    >
                        <ColorField
                            value={editLabelBackground}
                            onChange={(newValue) => onUpdateField('labelBackground', newValue)}
                        />
                    </FormControl>
                ) : null}
            </div>
        </div>
    );
}

DocumentSharePage.propTypes = propTypes;
DocumentSharePage.defaultProps = defaultProps;

export default DocumentSharePage;
